input[type="text"],
input[type="email"],
input[type="password"],
input[type="number"],
textarea {
  &:not(.tm-input):not(.MuiInputBase-input) {
    display: block;
    width: 100%;
    border: none;
    line-height: 24px;
    padding: 16px;
    color: $fontColor;
    background: $inputBackground;
    border-radius: 8px;
    &:hover:not([disabled]) {
      background: $inputBackgroundActive; }
    &:focus {
      outline: none;
      background: $inputBackgroundActive; }
    &:disabled {
      background: $inputBackgroundDisabled; }
    .form-input-attachments & {
      border-radius: 2px 0 0 2px; }
    .table & {
      box-shadow: none;
      border: none;
      border-radius: 0;
      background: none; }
    .form--simple & {
      box-shadow: none;
      padding: 8px;
      font-size: 14px;
      line-height: 18px; } } }

.form-input-attachments {
  display: flex;
  label {
    display: block;
    line-height: 20px;
    padding: 10px;
    border-left: none;
    background: $inputBackground;
    border-radius: 0 8px 8px 0;
    font-size: 14px;
    font-weight: 500;
    color: $fontColor;
    cursor: pointer; }
  .form-item-input--number & {
    display: inline-flex; } }
