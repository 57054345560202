.modal {
  position: fixed;
  display: flex;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background: rgba($tmblue, .3);
  z-index: 2000000001;
  padding: 10px;
  opacity: 1;
  visibility: visible;
  backdrop-filter: blur(2px);
  &.modal--transition,
  &.modal--transition .modal-inner {
    transition: .3s all ease-in-out; } }

.modal--concrete {
  background: rgba($concrete, .7); }

.modal--green {
  background: rgba($green2, .7); }

.modal--hidden {
  opacity: 0;
  visibility: hidden;
  .modal-inner {
    transform: translateY(30px); } }

.modal-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2; }

.modal-inner {
  background: #fff;
  margin: auto;
  width: 100%;
  max-width: 600px;
  @include shadow(16);
  position: relative;
  border-radius: 8px;
  transform: translateY(0);
  z-index: 3;
  h3 {
    margin: 0 0 20px;
    font-size: 20px;
    font-weight: 300;
    color: $tmblue; }
  .modal--tiny & {
    max-width: 400px; } }

.modal-form {
  padding: 15px 20px 0; }

.modal-content .messages {
  padding: 10px 10px 0; }

.modal-inner--rangepicker,
.modal--rangepicker .modal-inner {
  max-width: 520px; }

.modal-inner--mediumplus,
.modal--mediumplus .modal-inner {
  max-width: 760px; }

.modal-inner--large,
.modal--large .modal-inner {
  max-width: 900px; }

.modal-inner--largeplus,
.modal--largeplus .modal-inner {
  max-width: 1100px; }

.modal-inner--xl,
.modal--xl .modal-inner {
  max-width: 1200px; }

.modal-content-inner {
  padding: 20px;
  .form-item:last-child {
    padding-bottom: 0; } }

.modal-content-inner-intro {
  border-bottom: 1px solid $borderLightGrey;
  margin-bottom: 20px; }

.modal-content-inner-intro-title {
  font-size: 18px;
  font-weight: 500;
  color: #0d3b70; }

.modal-content-action {
  padding: 10px 20px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  background: $pageBackground;
  .form-submit-content {
    padding-top: 4px;
    font-size: 14px;
    padding-left: 10px; } }

.modal-close {
  position: absolute;
  top: 0;
  right: 0;
  border: none;
  padding: 0;
  background: none;
  color: $fontColorLight;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  font-size: 16px;
  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba($orangeLight, .9); }
  &:hover {
    color: $fontColor; } }

.modal-header {
  height: 80px;
  padding: 16px 24px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  gap: 5px;
  i.fa {
    color: $blue; }
  .modal-header-title {
    margin: auto 0; } }

.modal-header-close {
  position: absolute;
  top: 12px;
  right: 10px;
  width: 36px;
  height: 36px;
  border: none;
  background: none;
  line-height: 36px;
  border-radius: 50%;
  font-size: 14px;
  padding: 0;
  color: $fontColorLight;
  &:hover {
    color: #fff;
    background: $lightGrey; }
  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px $yellow; } }

.modal-header-title {
  flex: 0 0 auto;
  font-weight: 500; }

.modal-header-info {
  flex: 0 0 auto;
  color: $fontColorLight;
  font-size: 14px;
  i {
    margin-right: 6px; } }

.modal-video {
  video {
    display: block;
    width: 100%;
    &:focus {
      outline: none;
      box-shadow: 0 0 0 2px $yellow; } } }

@media screen and (max-width: 900px) {
  .modal--responsive {
    padding: 0;
    .modal-inner {
      border-radius: 0;
      box-shadow: none;
      height: 100%;
      max-width: 100%;
      margin: 0;
      display: flex;
      flex-direction: column; }
    .modal-content {
      flex-grow: 1;
      overflow: auto; } } }

// Panel
.modal--panel {
  padding: 0;
  .modal-inner {
    max-width: 1100px;
    border-radius: 0;
    height: 100%;
    margin: 0 0 0 auto;
    display: flex;
    flex-direction: column; }
  .modal-content {
    flex-grow: 1;
    display: flex;
    flex-direction: column; } }

.modal--full {
  .modal-inner {
    max-width: 1100px;
    // border-radius: 0
    height: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column; }
  .modal-content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden; } }
