.form-field--checkboxes {}

.form-checkbox {
  display: flex;
  width: 100%;
  text-align: left;
  background: none;
  border: none;
  padding: 5px 0;
  margin-bottom: 5px;
  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba($orangeLight, .9); }
  &:last-child {
    margin: 0; } }

.form-checkbox-icon-wrapper {
  display: block;
  border: none;
  background: none;
  padding: 0;
  cursor: pointer;
  &:focus {
    outline: none;
    .form-checkbox-icon {
      box-shadow: 0 0 0 2px rgba($orangeLight, .9); } } }

.form-checkbox-icon {
  display: block;
  width: 18px;
  height: 18px;
  line-height: 18px;
  border-radius: 2px;
  border: 1px solid $borderLighterBlue;
  background: $pageBackground;
  text-align: center;
  i.fa {
    font-size: 9px;
    display: block;
    width: 18px;
    height: 18px;
    line-height: 18px;
    position: relative;
    left: -1px; }
  .form-checkbox--checked &,
  &.form-checkbox-icon--checked {
    border-color: darken($lightBlue, 5%);
    background: $lightBlue;
    color: #fff; }
  .form-checkbox--checked & {} }

.form-checkbox-label {
  display: block;
  padding-left: 8px;
  font-size: 14px;
  line-height: 18px;
  flex: 1 0;
  cursor: pointer;
  color: $fontColor;
  &.form-checkbox-label-disabled {
    cursor: default; }
  .form--simple & {
    font-size: 13px;
    color: $fontColor; }
  .form-item-style--multiline & {
    line-height: 14px; } }

.form-checkbox-label-link {
  margin-left: 4px; }

.form-checkbox-empty {
  text-align: center;
  color: $fontColorLight;
  padding: 10px; }

.form-item-style--button {
  .form-checkbox {
    padding: 0;
    background: #fff;
    border-radius: 8px;
    border: 1px solid $borderGrey;
    &:hover {
 }      // border: 1px solid $borderLightBlue
    &.form-checkbox--checked {
 } }      // border: 1px solid $borderLightBlue
  .form-checkbox-icon-wrapper {
    padding: 10px; }
  .form-checkbox-label {
    padding: 10px 10px 10px 0; } }

.form-item-style--switch {
  // .form-checkbox-icon-wrapper
  .form-checkbox-icon {
    display: block;
    height: 22px;
    width: 40px;
    background: tomato;
    box-shadow: inset 0 0 0 1px rgba(#000, .15);
    border-radius: 15px;
    position: relative;
    border: none;
    &:after {
      content: "";
      display: block;
      position: absolute;
      top: 3px;
      left: 3px;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      background: #fff;
      box-shadow: 0 0 0 1px rgba(#000, .2);
      transition: .2s all ease-in-out; }
    .fa {
      display: none; } }
  .form-checkbox--checked .form-checkbox-icon {
    background: $green;
    &:after {
      transform: translateX(17px); } }
  .form-checkbox-icon-wrapper:focus .form-checkbox-icon {
    box-shadow: inset 0 0 0 1px rgba(#000, .3); }
  .form-checkbox-label {
    line-height: 22px; } }

.form-item-style--switch-disabled {
  .form-checkbox-icon {
    background: $lightBlue; }
  .form-checkbox--checked .form-checkbox-icon {
    background: tomato; } }
