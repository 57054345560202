
.quote-list,
.quote-placeholder {
  position: relative;
  margin: 0 0 24px;
  .survey-tabs + &,
  .tabs + & {
    margin-top: 0;
    padding: 0;
    .breakpoint-under-medium & {
      .quote-list-cards {
        margin: 20px 10px 24px; } } } }

.survey-tabs + .quote-list .quote-list-title,
.tabs + .quote-list .quote-list-title {
  background: #fff;
  padding: 15px;
  margin-bottom: 20px;
  border-radius: 8px; }

.quote-list--slider {
  .breakpoint-under-small & {
    padding: 0; } }

.quote-placeholder {
  min-height: 200px; }

.quote-mobile-link {
  display: none;
  text-align: center;
  padding: 0 10px 10px;
  .breakpoint-under-small & {
    display: block;
    padding: 20px 10px; } }

.quote-list-title {
  display: flex;
  margin-bottom: 6px;
  margin-top: 24px;
  .breakpoint-under-small & {
    display: block; } }

.breakpoint-under-small .quote-list-title-link {
  display: none; }

.quote-list-title-text {
  color: $blue;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  overflow: hidden;
  flex-grow: 1; }

.quote-list-title-link a {
  display: block; }

.quote-list-cards {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 0 -8px;
  .quote-list--loading & {
    opacity: .6; } }

.quote-list-card {
  width: 25%;
  padding: 8px;
  .breakpoint-under-medium & {
    width: 50%; }
  .breakpoint-under-small & {
    width: 100%; } }

.quote-slider {
  margin-bottom: 40px;
  margin: 0 -8px;
  margin: 0 -8px;
  margin-bottom: 40px;
  position: relative;
  .slick-list {
    &:after,
    &:before {
      content: " ";
      display: block;
      width: 8px;
      height: 100%;
      position: absolute;
      z-index: 3;
      top: 0; }
    &:before {
      left: 0;
      background: linear-gradient(90deg, rgba(#f1f1ef, 1) 0%, rgba(#f1f1ef, 0) 100%); }
    &:after {
      right: 0;
      background: linear-gradient(90deg, rgba(#f1f1ef, 0) 0%, rgba(#f1f1ef, 1) 100%); } }
  .quote-list--has-survey & .slick-arrow {
    transform: translate(0, calc(-50% - 15px)); } }

.quote-slider-card {
  padding: 8px; }

.quote-list-empty {
  margin-top: 10px; }

.quote-list-empty-inner {
  @extend .responses-empty-inner; }

.quote-loader {
  @extend .responses-loader;
  z-index: 2; }

.quote-loader-inner {
  @extend .responses-loader-inner; }
