$tabsBg: darken(#fff, 3%);
$tabsBorder: darken($borderLighterGrey, 2%);

.survey-tabs {
  display: block;
  background: $tabsBg;
  // box-shadow: $defaultBoxShadow
  text-align: left;
  border-top: 1px solid rgba($tabsBorder, .8);
  border-left: 1px solid rgba($tabsBorder, .8);
  border-right: 1px solid rgba($tabsBorder, .8); }

.survey-tabs-scroll {
  overflow-x: auto; }

.survey-tabs-gradient {
  position: relative;
  .breakpoint-under-small &:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    width: 46px;
    height: 46px;
    background: linear-gradient(90deg, rgba($tabsBg, 0), rgba($tabsBg, 1));
    pointer-events: none; } }

.survey-tabs-list {
  padding: 5px 5px 0;
  white-space: nowrap;
  display: flex;
  -webkit-overflow-scrolling: touch;
  border-bottom: 1px solid $tabsBorder; }

.survey-tabs-item,
a.survey-tabs-item {
  display: block;
  background: none;
  border: none;
  padding: 10px 15px 13px 12px;
  margin-bottom: -1px;
  border: 1px solid transparent;
  border-bottom-color: $tabsBorder;
  font-size: 14px;
  border-top-right-radius: 2px;
  border-top-left-radius: 2px;
  text-decoration: none;
  color: #333;
  &:hover {
    border-bottom-color: $green2;
    opacity: 1; }
  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px $yellow; }
  i.fa {
    margin-right: 6px;
    font-size: 12px;
    color: $fontColorLight; }
  // &:last-child
 }  //   margin-left: auto

.survey-tabs-item--active,
.survey-tabs-item--active:hover,
a.survey-tabs-item--active,
a.survey-tabs-item--active:hover {
  background: #fff;
  border-top-color: $tabsBorder;
  border-left-color: $tabsBorder;
  border-right-color: $tabsBorder;
  border-bottom-color: #fff;
  i.fa {
    color: $green2; } }

.breakpoint-under-medium .survey-tabs-item,
.breakpoint-under-medium a.survey-tabs-item {
  padding: 7px 10px 10px;
  font-size: 13px; }

.breakpoint-under-medium .survey-tabs-item--active,
.breakpoint-under-medium .survey-tabs-item:hover,
.breakpoint-under-medium a.survey-tabs-item--active,
.breakpoint-under-medium a.survey-tabs-item:hover {}

/* Survey message */

.survey-message-wrapper {
  position: relative; }

.survey-message {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  padding: 10px;
  border-radius: 2px;
  background: rgba($green2, .9);
  color: #fff;
  text-shadow: 1px 1px 1px rgba(#000, .1);
  font-weight: 500;
  box-shadow: $defaultBoxShadow;
  font-size: 14px;
  font-weight: 500;
  i {
    color: rgba(#000, .2);
    margin-right: 10px;
    font-size: 12px;
    text-shadow: none; } }

/* Survey settings */
.survey-settings {
  background: #fff;
  border-radius: 8px;
  @include shadow(6);
  .form > .form-fieldset {
    .breakpoint-over-small &:first-child {
      > .form-fieldset-title,
      > .form-fieldset-fields {
        padding-top: 30px; } } } }

/* Survey editor */
.survey-editor-info {
  background: #fff;
  border: 1px solid $borderLighterGrey;
  border-top: none;
  padding: 20px;
  margin-bottom: 20px;
  .breakpoint-under-medium & {
    padding: 10px; } }

.survey-send {
  background: #fff;
  box-shadow: $defaultBoxShadow;
  padding: 20px; }

.survey-sendlog {
  background: #fff;
  box-shadow: $defaultBoxShadow;
  padding: 20px; }

/* Remove */

.survey-settings-remove {
  padding: 20px;
  border-top: 1px dashed $tabsBorder;
  // background: #fafafa
  margin-top: 20px;
  .button-plain:hover {
    color: $red; } }

.survey-box {
  background: #fff;
  box-shadow: $defaultBoxShadow;
  padding: 20px;
  position: relative;
  margin-bottom: 20px; }

@import "survey/info";
@import "survey/step";
@import "survey/field";
@import "survey/confirm";
@import "survey/editField";
@import "survey/languageSwitcher";
@import "survey/dropzone";
@import "survey/answerView";
@import "survey/banner";
@import "survey/thanks";
@import "survey/analytics";
