@import "variables";

.conditions-info-items {
  margin-top: 3px;
  line-height: 16px;
  padding: 2px 10px;
  border-radius: 10px;
  font-size: 11px;
  letter-spacing: .3px;
  display: inline-block;
  margin-left: 8px;
  font-weight: 500;
  //background: $concrete
  background: $green2;
  color: #fff;
  i.fa {
    margin-right: 4px;
    font-size: 10px;
    color: rgba(#000, .5); } }

.conditions-info-edit {
  display: inline-block;
  border: none;
  background: none;
  padding: 0;
  line-height: 26px;
  font-size: 12px;
  font-weight: 500;
  color: $fontColorLight;
  opacity: 0;
  .survey-editor-step:hover & {
    opacity: 1; }
  i {
    font-size: 12px;
    margin-right: 6px; }
  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px $yellow; }
  &:hover {
    color: $blue; } }

.conditions-editor {
  .form-item {
    padding: 0; }
  .edit-field-values {
    padding: 20px; } }

.conditions {
  background: $pageBackground;
  padding: 5px;
  border-radius: 8px; }

.conditions-item {
  z-index: 1001;
  display: flex;
  background: #fff;
  border-radius: 4px;
  margin-bottom: 5px;
  @include shadow();
  &:hover {
    border-color: $blue; } }

.form--simple .conditions-item-input,
.conditions-item-input {
  flex-grow: 1;
  padding: 5px 5px 5px 0;
  select {
    display: block;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    background: none;
    border-radius: 0;
    border: none;
    line-height: 20px;
    padding: 2px 5px;
    color: $fontColor;
    cursor: pointer;
    font-size: 14px;
    font-weight: 400;
    // box-shadow: inset 1px 1px 2px rgba(#000, .07)
    box-shadow: 0px 1px 3px rgba(#000, .05);
    background: darken(#fff, 2%);
    border: 1px solid $borderLightGrey;
    border-radius: 2px;
    &:hover {
      border-color: $blue; }
    &:focus {
      outline: none;
      box-shadow: 0 0 0 2px $yellow; }
    &:disabled {
      color: #9b9b9b; } }
  input[type="text"]:not(.tm-input):not(.MuiInputBase-input) {
    display: block;
    width: 100%;
    line-height: 20px;
    padding: 2px 5px;
    box-shadow: 0px 1px 3px rgba(#000, .05);
    background: darken(#fff, 2%);
    border: 1px solid $borderLightGrey;
    border-radius: 2px;
    font-size: 14px;
    font-weight: 400; } }

.conditions-item-input-join select {
  text-align: center; }

.conditions-item-input-join,
.conditions-item-input-operator {
  padding-right: 6px; }

.conditions-item-input-row {
  display: flex;
  padding: 3px 0; }

.conditions-item-input-test--text {
  flex-grow: 1; }

.conditions-item-handle {
  flex: 0 0 30px;
  color: $fontColorLight;
  text-align: center;
  display: flex;
  cursor: move;
  i.fa {
    font-size: 12px;
    display: block;
    line-height: 12px; }
  &:hover {
    color: $yellow; } }

.conditions-handle {
  margin: auto; }

.conditions-item-remove {
  flex: 0 0 30px;
  display: flex;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  button {
    display: block;
    width: 24px;
    height: 24px;
    border: none;
    background: none;
    color: $fontColorLight;
    text-align: center;
    border-radius: 50%;
    margin: auto;
    padding: 0;
    i.fa {
      font-size: 11px;
      position: relative;
      top: -2px; }
    &:focus {
      outline: none;
      box-shadow: 0 0 0 2px $yellow; }
    &:hover {
      background: $red;
      color: #fff; } } }

.conditions-create {
  display: flex;
  .conditions--edit & {
    margin-top: 10px;
    padding-top: 10px; } }

.conditions-create-icon {
  i.fa {
    display: block;
    width: 30px;
    height: 25px;
    line-height: 25px;
    text-align: center;
    color: $fontColorLight;
    font-size: 11px; } }

.conditions-create-button {
  button {
    display: inline-block;
    margin-right: 5px;
    line-height: 15px;
    padding: 4px 10px 4px 6px;
    border: none;
    background: #fff;
    border-radius: 4px;
    color: $fontColor;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    @include shadow();
    span {
      color: $fontColorLight;
      position: relative;
      top: -1px; }
    &:focus {
      outline: none;
      box-shadow: 0 0 0 2px $yellow; }
    &:hover {
      border-color: $blue;
      opacity: .8;
      span {
        color: $blue; } } } }
