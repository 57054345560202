.survey-editor-banner {
  background: #fff;
  border: 1px solid $borderGrey;
  border-radius: 8px;
  display: flex;
  .breakpoint-under-small & {
    display: block; } }

.survey-editor-banner-content {
  padding: 60px 30px;
  flex-grow: 1;
  h2 {
    margin: 0;
    font-weight: 500;
    color: $blue;
    font-size: 26px;
    line-height: 120%; }
  p {
    font-size: 18px;
    line-height: 120%;
    font-weight: 300;
    margin: 0.5em 0;
    & + p {
      font-size: 16px;
      color: $fontColorLight; } }
  .button {
    margin-top: 1em; } }

.survey-editor-banner-image {
  width: 380px;
  position: relative;
  overflow: hidden;
  .breakpoint-under-small & {
    height: 200px;
    width: 100%; } }

$radius: 700px;
.survey-editor-banner-image-circle {
  position: absolute;
  top: 50%;
  left: 0;
  width: $radius;
  height: $radius;
  background-color: lighten($lightGrey, 2%);
  background-image: url('https://d2nce6johdc51d.cloudfront.net/img/banner.svg');
  background-size: 50%;
  background-position: -20px -20px;
  // transform: rotate(-20deg)
  border-radius: 50%;
  box-shadow: inset 2px 0 0 rgba(#000, .1);
  margin-top: $radius / 2 * -1;
  .breakpoint-under-small & {
    left: 50%;
    top: 0;
    margin-top: 0;
    margin-left: $radius / 2 * -1;
    box-shadow: inset 0 2px 0 rgba(#000, .1); } }
