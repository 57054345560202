.form-field--radios {}

.form-radio {
  display: flex;
  width: 100%;
  text-align: left;
  background: none;
  border: none;
  padding: 5px 0;
  cursor: pointer;
  margin-bottom: 5px;
  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba($orangeLight, .9); }
  &:last-child {
    margin: 0; }
  &[disabled] {
    opacity: .6; } }

.form-radio-icon-wrapper {}

.form-radio-icon {
  display: block;
  width: 18px;
  height: 18px;
  line-height: 18px;
  border-radius: 9px;
  border: 1px solid $borderLightGrey;
  background: $lightGrey;
  text-align: center;
  i.fa {
    font-size: 9px;
    display: block;
    width: 18px;
    height: 18px;
    line-height: 18px;
    position: relative;
    left: -1px; }
  .form-radio--checked & {
    border-color: darken($lightBlue, 10%);
    background: $lightBlue;
    color: #fff; }
  .form-radio--checked & {} }

.form-radio-label {
  display: block;
  padding-left: 8px;
  word-break: break-word; }

.form-item-style--buttons .form-field--radios {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  .form-wizard & {
    padding-top: 10px; }
  .form-radio {
    width: auto;
    padding: 12px 12px 12px 8px;
    margin: 0 10px 10px 0;
    background: #fff;
    font-size: 14px;

    border-radius: 8px;
    background: $inputBackground;
    color: $fontColor;
    &:hover {
      background: $inputBackgroundActive; }
    .form-radio-icon {
      background: #fff; } }
  .form-radio--checked {
    .form-radio-icon {
      background-color: $lightBlue;
      border-color: darken($lightBlue, 5%); } }
  .breakpoint-under-small & {
    display: block;
    .form-radio {
      margin: 10px 0;
      width: 100%; } } }

.breakpoint-over-medium .form-item-style--boxes .form-field--radios {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  padding: 5px;
  border: 1px solid lighten($borderLightGrey, 4%);
  background: lighten($borderLighterGrey, 4%);
  border-radius: 3px;
  .form-wizard & {
    padding-top: 10px; }
  .form-radio {
    display: block;
    width: calc(25% - 10px);
    padding: 10px;
    margin: 5px;
    background: #fff;
    box-shadow: 0 2px 6px rgba(#000, .05);
    border-radius: 2px;
    border: 1px solid $borderLightGrey;
    height: 120px;
    text-align: center;
    &:hover {
      border-color: $lightBlue; }
    &:focus {
      box-shadow: 0 0 0 2px $yellow; } }
  .form-radio--checked {
    border-color: $lightBlue;
    box-shadow: 0 4px 8px rgba($lightBlue, .3);
    text-decoration: underline $lightBlue;
    &:focus {
      box-shadow: 0 4px 8px rgba($lightBlue, .35); }
    &:hover {
      border-color: $lightBlue; }
    .form-radio-icon {
      background-color: $lightBlue;
      border-color: darken($lightBlue, 5%); } }
  .form-radio-icon-wrapper {
    display: none; }
  .form-radio-label {
    padding: 0; }
  .breakpoint-under-small & {
    display: block;
    .form-radio {
      margin: 10px 0;
      width: 100%; } } }

.form-item-style--center .form-field--radios {
  justify-content: center; }

.form-item-style--well .form-field--radios,
.form-item-style--well .form-field--checkboxes,
.form-item-style--well .form-checkbox-wrapper,
.breakpoint-under-medium .form-item-style--boxes .form-field--radios {
  position: relative;
  .form-checkbox,
  .form-radio {
    margin: 0 0 4px;
    padding: 6px;
    background: $pageBackgroundDark;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 400;
    &:hover {
      background: $pageBackgroundDarker; }
    &:focus {
      background: lighten($borderLighterGrey, 4%); } }
  .form-checkbox-icon,
  .form-radio-icon {
    background: #fff; }
  .form-checkbox--checked .form-checkbox-icon,
  .form-radio--checked .form-radio-icon {
    background: $lightBlue;
    border-color: darken($lightBlue, 10%); } }
