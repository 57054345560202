.form-field--select {
  select {
    display: block;
    width: 100%;
    border-radius: 0;
    border: none;
    line-height: 24px;
    padding: 16px;
    color: $fontColor;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    cursor: pointer;
    background-color: $inputBackground;
    background-image: $arrowDown;
    background-repeat: no-repeat;
    background-position: calc(100% - 16px) 50%;
    background-size: 12px 12px;
    border-radius: 8px;
    &:hover:not([disabled]) {
      background-color $inputBackgroundActive {} }
    &:focus {
      outline: none;
      background-color: $inputBackgroundActive; }
    &:disabled,
    &:disabled:hover {
      background-color: $inputBackgroundDisabled;
      cursor: default;
      text-decoration: none; }
    option {
      display: block;
      width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis; }
    .table & {
      box-shadow: none;
      border: none;
      border-radius: 0;
      background-color: transparent; }
    .form--simple & {
      box-shadow: none;
      padding: 8px;
      font-size: 14px;
      line-height: 18px; } } }
