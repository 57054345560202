@import "variables";

.quote-card-inner {
  position: relative; }

.quote-card-main {
  border-radius: 8px;
  background: #fff;
  border: 1px solid $borderGrey;
  .quote-card--plain & {
    box-shadow: none; } }

.quote-card-text {
  position: relative;
  display: flex;
  height: 160px;
  padding: 10px 10px 30px;
  color: $fontColor;
  .quote-card-video + & {
    min-height: 100px;
    .quote-card-text-icon {
      top: 0; } }
  .quote-card--video & {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    color: #fff;
    background: rgba(#000, .5);
    text-shadow: 1px 1px 3px rgba(#000, .5);
    opacity: 1;
    visibility: visible;
    transition: .3s all ease-in-out;
    transition-delay: .1s;
    pointer-events: none;
    img {
      display: none; }
    .quote-card-text-inner {
      transition: .1s all ease-in-out;
      transition-delay: 0s; } }
  .quote-card--video:hover & {
    opacity: 0;
    visibility: hidden;
    transition-delay: 0s;
    .quote-card-text-inner {
      transition-delay: .1s; } }
  .quote-card--horizontal & {
    height: auto; } }

.quote-card-text-icon {
  position: absolute;
  top: 10px;
  left: 10px;
  img {
    height: 30px; }
  .breakpoint-under-small & {
    top: 0;
    left: 0;
    img {
      transform: scale(.6); } } }

.quote-card-text-inner {
  position: relative;
  z-index: 2;
  text-align: center;
  font-size: 15px;
  line-height: 18px;
  margin: auto;
  p {
    margin: 0 0 20px;
    &:last-child {
      margin: 0; } }
  .quote-card-text--small & {
    font-size: 12px;
    line-height: 16px; } }

.quote-card-video-inner {
  position: relative;
  img {
    display: block;
    width: 100%;
    height: 160px;
    margin: 0 auto;
    object-fit: cover;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    &:focus {
      outline: none;
      box-shadow: 0 0 0 2px $yellow; } }
  .quote-card--horizontal & {
    display: flex; }
  svg {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    visiblity: hidden;
    opacity: 0;
    transition: .2px opacity ease-in-out;
    .quote-card--video:not(.quote-card--horizontal):hover & {
      opacity: 1;
      visibility: visible; } } }

.quote-card-person {
  display: block;
  background: none;
  border: none;
  padding: 0;
  width: 100%;
  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px $yellow; }
  .quote-card-video + & {
    padding-top: 60px; }
  .quote-card--horizontal & {
    border-top: 1px solid $borderLighterBlue; } }

.quote-card-person-card {
  max-width: 400px;
  margin: 0 auto;
  border-top: 1px solid $borderLighterBlue;
  padding: 10px 10px 8px;
  text-align: center;
  position: relative;
  font-size: 14px;
  .quote-card--horizontal & {
    max-width: 100%;
    border: none; } }

.quote-card-person-image {
  width: 72px;
  height: 72px;
  position: absolute;
  top: 0;
  left: 50%;
  margin: -36px 0 0 -36px;
  .quote-card--video:not(.quote-card--horizontal) & {
    transition: .1s all ease-in-out;
    transform: translateY(0); }
  .quote-card--video:not(.quote-card--horizontal):hover & {
    opacity: 0;
    transform: translateY(10px); }
  .quote-card--horizontal & {
    position: static;
    margin: 0 auto; } }

.quote-card-person-image img {
  display: block;
  width: 72px;
  height: 72px;
  object-fit: cover;
  border-radius: 50%;
  box-shadow: 0 4px 10px rgba(#000, .1);
  background-color: #fff; }

.quote-card-person-name {
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
  .quote-card-person:hover & {
    text-decoration: underline;
    text-decoration-color: $yellow; } }

.quote-card-person-image + .quote-card-person-content {
  margin-top: 36px; }

.quote-card-person-content {
  .quote-card--video:not(.quote-card--horizontal) & {
    transition: .1s all ease-in-out;
    transform: translateY(0); }
  .quote-card--video:not(.quote-card--horizontal):hover & {
    transform: translateY(-18px); }
  .quote-card--horizontal & {
    margin-top: 10px; } }

.quote-card-person-info {
  font-size: 14px;
  line-height: 18px;
  height: 18px; }

.quote-card-person-logo {
  margin-top: 8px;
  img {
    margin: auto;
    display: block;
    width: 64px;
    height: 40px;
    object-fit: contain; } }

.quote-card-info {}

.quote-card-date {
  font-size: 12px;
  text-align: center;
  color: $fontColorLight;
  padding: 0 10px 10px;
  i.fa {
    margin-right: 3px;
    font-size: 10px;
    color: lighten($fontColorLight, 2%); } }

.quote-card-survey {
  padding: 10px 20px 5px;
  text-align: center;
  a {
    text-decoration: none;
    display: inline-block;
    max-width: 100%;
    overflow: hidden;
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
    padding: 0 10px;
    border-radius: 10px;
    background: $lightGrey;
    color: $fontColor;
    &:focus {
      outline: none;
      box-shadow: 0 0 0 2px $yellow; } } }

.quote-card-action {
  display: flex; }

.quote-card-action-publish {
  flex-grow: 1; }

.quote-card-action-order {
  flex-grow: 1; }

.quote-card-action-cta {
  button {
    display: block;
    border: none;
    background: none;
    font-size: 14px;
    font-weight: 400;
    line-height: 26px;
    padding: 5px 15px;
    color: $fontColor;
    i.fa {
      font-size: 12px;
      color: lighten($fontColorLight, 5%); }
    &:focus {
      outline: none;
      box-shadow: 0 0 0 2px $yellow; }
    &:hover {
      text-decoration: underline;
      text-decoration-color: $yellow;
      i.fa {
        color: $green2; } } } }
