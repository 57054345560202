.button-old {
  display: inline-block;
  line-height: 18px;
  padding: 10px 16px;
  border-radius: 4px;
  background: $tmblue;
  color: #fff;
  font-weight: 500;
  font-size: 14px;
  border: none;
  text-decoration: none;
  position: relative;
  overflow: hidden;
  &:hover {
    opacity: .8;
    color: #fff; }
  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px $yellow; }
  i.fa, .icon {
    margin-right: 6px;
    margin-left: -4px;
    font-size: 12px;
    color: $orangeLight; }
  &[disabled] {
    opacity: .6;
    cursor: default; } }

.button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0;
  border: 0;
  margin: 0;
  border-radius: 0;
  padding: 0;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  text-decoration: none;
  color: inherit;
  font-weight: 600;
  line-height: 1.7142857142857142;
  font-size: 0.875rem;
  font-family: Roboto,sans-serif;
  min-width: 64px;
  padding: 6px 16px;
  border-radius: 8px;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  color: #000;
  background-color: $yellow;

  &:hover {
    -webkit-text-decoration: none;
    text-decoration: none;
    background-color: #ffb100;
    box-shadow: none; }

  &:active {
    box-shadow: 0px 5px 5px -3px rgba(145, 158, 171, 0.2),0px 8px 10px 1px rgba(145, 158, 171, 0.14),0px 3px 14px 2px rgba(145, 158, 171, 0.12); }

  i.fa, .icon {
    margin-right: 6px;
    margin-left: -4px;
    font-size: 12px; } }

.button--orange {
  background: $orange;
  i.fa {
    color: #fff; } }

.button--red {
  background: tomato;
  text-shadow: 1px 1px 1px rgba(#000, .1);
  i.fa {
    color: rgba(#000, .4); }
  &.button--icon i.fa {
    color: #fff; }
  &:hover {
    background: darken(tomato, 10%); } }

.button--green-old {
  background: $green2;
  // box-shadow: inset 0 0 0 1px rgba(#000, .15)
  text-shadow: 1px 1px 1px rgba(#000, .1);
  i.fa {
    color: rgba(#000, .2); }
  &.button--icon i.fa {
    color: #fff; } }

.button--green {
  color: $fontColor;
  background-color: #42CC54;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  &:hover {
    color: $fontColor;
    text-decoration: none;
    background-color: #248631; } }

.button--yellow {
  background: $yellow;
  box-shadow: inset 0 0 0 1px rgba(#000, .15);
  text-shadow: 1px 1px 1px rgba(#000, .1);
  i.fa {
    color: rgba(#000, .2); } }

.button--grey {
  background: $pageBackgroundDark;
  color: $fontColor;
  box-shadow: none !important;
  i.fa {
    color: rgba(#000, .4); }
  &:hover {
    color: $fontColor;
    opacity: 1;
    background: darken($pageBackgroundDark, 5%); } }

.button--white {
  background: #fff;
  color: $fontColor;
  //+shadow(4, rgba($blue, .06))
  box-shadow: 0 8px 16px 0 rgba(53, 131, 190, 0.24) !important;
  i.fa {
    color: $blue; }
  &:hover {
    @include shadow(6, rgba($blue, .06));
    opacity: 1;
    color: $blue;
    background: darken(#fff, 5%); } }

.button--ghost-yellow {
  background: #fff;
  color: $fontColor;
  box-shadow: inset 0 0 0 1px $yellow;
  i.fa {
    color: $yellow; }
  &:hover {
    color: $fontColor;
    box-shadow: inset 0 0 0 1px $yellow;
    background: $lightGrey; } }

.button-overlay-loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba($blue, .7);
  display: flex;
  i.fa {
    margin: auto;
    display: block; }
  .button--orange & {
    background: rgba($orange, .7); } }

a.button:hover {
  color: #fff;
  opacity: 1; }

a.button--grey:hover,
a.button--white:hover,
a.button-plain:hover,
a.button--plain:hover,
a.button--green:hover {
  color: $fontColor; }

.button--small-old {
  padding: 5px 12px;
  font-size: 12px;
  border-radius: 3px;
  i.fa {
    margin-right: 6px;
    font-size: 10px; }
  &.button--icon {
    padding: 5px 0;
    width: 28px;
    text-align: center; } }

.button--small {
  font-size: 0.8125rem;
  padding: 4px 10px;
  i.fa {
    margin-right: 6px;
    font-size: 10px;
    margin-left: 0; }
  &.button--icon {
    padding: 5px 0;
    width: 28px;
    text-align: center; } }

.button--icon-right i.fa {
  margin-right: -4px;
  margin-left: 6px; }

.button--icon-right.button--small i.fa {
  margin-right: -4px;
  margin-left: 6px; }

.button--tiny {
  padding: 3px 10px;
  font-size: 10px;
  i.fa {
    margin-right: 6px;
    font-size: 10px; } }

.button-plain {
  background: none;
  border: none;
  padding: 0;
  color: $fontColorLight;
  border-radius: 0;
  text-decoration: none;
  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba($orangeLight, .9); }
  &:hover {
    color: $fontColor; }
  i.fa {
    font-size: 12px;
    margin-right: 6px;
    position: relative;
    top: -1px; } }
//.button-plain
.button--plain {
  padding: 4px 5px;
  color: #1D3A6C;
  background-color: transparent;
  box-shadow: none;
  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba($orangeLight, .9); }
  &:hover {
    box-shadow: none;
    text-decoration: none;
    background-color: rgba(53, 131, 190, 0.08); }

  i.fa {
    font-size: 12px;
    margin-right: 6px;
    position: relative;
    top: -1px; } }

.button-plain--small,
.button-plain-small {
  font-size: 12px;
  font-weight: 500; }

.button-plain-red:hover {
  color: tomato; }

.button-square {
  display: inline-block;
  background: #fff;
  @include shadow(3);
  border-radius: 8px;
  border: 1px solid $borderLighterBlue;
  line-height: 20px;
  padding: 5px 10px;
  font-size: 12px;
  font-weight: 500;
  color: $fontColor;
  &:hover {
    border-color: $blue; }
  &:focus {
    outline: none;
    box-shadow: 0 0 0 1px $blue;
    border-color: $blue; }
  i.fa {
    font-size: 10px;
    color: $fontColorLight;
    margin-right: 6px;
    position: relative;
    top: -1px; }
  &[disabled] {
    opacity: .6;
    cursor: default;
    &:hover {
      border-color: $borderLightGrey; } } }

.button--icon {
  i.fa {
    margin: 0; } }

.button--ghost {
  background: #fff;
  color: $fontColor;
  box-shadow: inset 0 0 0 1px $borderLighterBlue;
  i.fa {
    color: $fontColorLight; }
  &:hover {
    color: $fontColor;
    background: $lighterGrey;
    box-shadow: inset 0 0 0 1px $borderLightBlue; } }
