.embed {
  margin-bottom: 15px; }

.embed-old {
  text-align: center;
  padding: 20px;
  border-top: 1px solid #f4f4f4;
  .embed-old-link {
    color: #9b9b9b;
    font-size: 14px;
    font-weight: 400;
    text-decoration: none;
    &:hover {
      text-decoration: underline; } } }

.embed-tabs {
  display: block;
  border-bottom: 1px solid $borderLighterGrey;
  background: darken(#fff, 2%);
  box-shadow: $defaultBoxShadow;
  text-align: left;
  padding: 5px 5px 0;
  .breakpoint-under-medium & {
    padding: 15px;
    text-align: center; } }

.embed-preview {
  padding: 15px 0 0; }

.embed-intro {
  font-size: 14px;
  padding: 15px 20px;
  border-bottom: 1px solid #f4f4f4;
  background: #fff;
  box-shadow: $defaultBoxShadow;
  @media screen and (max-width: $mediumBreakpoint) {
    padding: 10px; } }

.embed-page {
  background: #fff;
  margin-bottom: 15px;
  min-height: 100px;
  position: relative;
  border: 1px solid $borderGrey;
  border-radius: 8px;
  margin-top: 24px; }

.embed-use {
  padding: 20px; }

.embed-embed-info {
  padding: 30px 20px;
  border-bottom: 1px solid $borderLighterBlue;
  h3 {
    margin: 0 0 10px;
    color: $blue;
    font-size: 18px;
    font-weight: 600; }
  p {
    color: $fontColor;
    font-size: 16px;
    line-height: 1.3em;
    margin: 10px 0; } }

.embed--popup .embed-page > .embed-embed-info:first-child {
  padding-top: 20px; }

.embed-analytics {
  padding: 20px;
  border-bottom: 1px dashed $borderLighterGrey;
  position: relative; }

.embed-analytics-loading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  background: rgba(#fff, .6);
  i.fa {
    display: block;
    width: 40px;
    height: 40px;
    line-height: 40px;
    font-size: 20px;
    text-align: center;
    color: $yellow;
    margin: auto; } }

.embed-analytics-data {
  display: flex;
  .breakpoint-under-medium & {
    display: block; } }

.embed-analytics-title {
  color: $blue;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  margin-bottom: 20px;
  .breakpoint-under-medium & {
    margin: 0; } }

.embed-analytics-panel {
  width: 260px;
  padding-right: 15px;
  .breakpoint-under-medium & {
    width: 100%;
    padding: 0; } }

.embed-analytics-counts {
  .breakpoint-under-medium & {
    display: flex;
    margin: 0 -10px 20px; }
  .breakpoint-under-small & {
    display: block;
    margin: 0; } }

.embed-analytics-count {
  margin-bottom: 30px;
  background: $lighterGrey;
  border: 1px solid $borderLighterGrey;
  padding: 10px 10px;
  border-radius: 2px;
  .breakpoint-under-medium & {
    width: 50%;
    margin: 10px; }
  .breakpoint-under-small & {
    width: 100%;
    margin: 10px 0; } }

.embed-analytics-count-number {
  display: block;
  font-weight: 900;
  font-size: 60px;
  line-height: 60px; }

.embed-analytics-count-label {
  display: block;
  font-size: 16px;
  font-weight: 500;
  color: $blue;
  margin-bottom: 10px; }

.embed-analytics-count-info {
  margin-top: 10px;
  color: $fontColorLight;
  font-size: 14px; }

.embed-analytics-graph {
  flex-grow: 1;
  overflow: hidden;
  .dashboard-nps-dates {
    text-align: right;
    margin-bottom: 20px; } }

.embed-analytics-fake {
  height: 446px; }

.embed-settings-setting {
  display: flex;
  align-items: center; }

.embed-settings-setting-label {
  padding: 0 12px 0 0; }

.embed-edit {
  display: flex;
  @media screen and (max-width: $mediumBreakpoint) {
    display: block; } }

.embed-edit-editor {
  min-width: 30%;
  border-right: 1px solid $borderLightGrey;
  position: relative;
  .form-submit-button {
    padding: 15px;
    display: block;
    margin: auto; }
  .form-submit {
    // position: sticky
    bottom: 0;
    background: rgba(#fff, .8);
    z-index: 12;
    border-top: 1px solid $borderLighterGrey;
    margin-top: -1px; } }

.embed-edit-preview {
  min-width: 70%;
  padding: 0 10px 10px; }

.embed-edit-preview-title {
  font-size: 18px;
  padding: 15px 0; }

.embed-use-code-inner {
  background: $lightGrey;
  padding: 10px 15px;
  margin: 15px 0;
  border-radius: 8px;
  pre {
    margin: 0;
    padding: 0;
    font-size: 14px;
    line-height: 18px; } }

.embed-list-link {
  display: inline-block;
  position: relative; }

.embed-use-label {
  font-size: 14px; }

.copy-link {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 10px;
  border: none;
  opacity: 0;
  cursor: pointer; }

.embed-list-link-button, .embed-button {
  white-space: nowrap;
  display: inline-block;
  cursor: pointer;
  @extend .report-export-button;
  .survey-link:hover & {
    border-color: $blue; } }

.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 26px; }

.switch input {
  opacity: 0;
  width: 0;
  height: 0; }

.slider {
  border-radius: 24px;
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s; }

.slider:before {
  border-radius: 24px;
  position: absolute;
  content: '';
  height: 20px;
  width: 20px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s; }

input:checked + .slider {
  background-color: $tmblue; }

input:checked + .slider:before {
  -webkit-transform: translateX(14px);
  -ms-transform: translateX(14px);
  transform: translateX(14px); }

/* Survey */
.embed-surveys {
  position: relative; }

.embed-surveys-button {
  color: $fontColor;
  display: block;
  width: 100%;
  line-height: 20px;
  padding: 10px;
  padding-top: 30px;
  border: 0;
  text-align: left;
  background: #ffffff;
  white-space: nowrap;
  &:focus {
    box-shadow: inset 0 0 0 2px #ffca05;
    border-radius: 0;
    outline: none; } }

.embed-surveys-list {
  position: absolute;
  top: calc(100% - 1px);
  right: 0;
  background: #fff;
  width: 250px;
  border: 1px solid $grey;
  box-shadow: 0 2px 10px rgba(#000, .1);
  z-index: 10;
  display: none;
  .embed-surveys--open & {
    display: block; } }

.embed-surveys-list-button {
  display: flex;
  width: 100%;
  padding: 10px;
  border: none;
  background: none;
  text-align: left;
  overflow: hidden;
  font-size: 15px;
  border-bottom: 1px solid $grey;
  font-size: 12px;
  &:last-child {
    border: none; }
  &:focus {
    outline: none; } }

.embed-surveys-list-button-check {
  display: flex;
  height: 100%;
  color: rgba($fontColor, .5);
  padding-top: 2px;
  i {
    margin: auto; }
  .embed-surveys-list-button--selected & {
    color: $fontColor; } }

.embed-surveys-list-button-name {
  flex-grow: 1;
  padding-left: 10px; }

// Settings

.embed-settings {
  @extend .survey-settings; }

.embed-settings-remove {
  @extend .survey-settings-remove; }
