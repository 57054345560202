.breakpoint-over-small .embed-editor--fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999999;
  width: 100%;
  height: 100%;
  background: $lighterGrey;
  padding: 0 20px;
  overflow: auto; }

.embed-editor-columns {
  display: flex;
  margin-top: 20px;
  .breakpoint-under-small & {
    display: block; } }

.embed-editor-fields,
.embed-editor-css {
  flex: 0 0 300px; }

.embed-editor-preview {
  flex: 1; }

.embed-editor-preview-inner {
  background: #fff;
  border-radius: 8px;
  @include shadow();
  padding: 20px;
  position: sticky;
  top: 76px;
  max-height: calc(100vh - 76px);
  overflow: auto; }

.embed-editor-preview-button-wrapper {
  position: fixed;
  bottom: 50px;
  left: 0;
  text-align: center;
  width: 100%;
  padding: 0 10px 22px;
  z-index: 32; }

.embed-editor-preview-button {
  display: inline-block;
  color: #fff;
  background: $green2;
  border: none;
  line-height: 20px;
  padding: 8px 12px;
  border-radius: 18px;
  font-size: 14px;
  font-weight: 500;
  box-shadow: 0 2px 6px rgba(#000, .1);
  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px $yellow; }
  i.fa {
    color: rgba(#000, .5);
    margin-right: 5px; } }

.embed-editor--responsive {
  .embed-editor-preview {
    display: none; }
  .embed-editor-preview-inner {
    max-height: 100000px;
    position: static; } }

.embed-editor--responsive.embed-editor--preview {
  .embed-editor-preview {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 31;
    overflow: auto; } }
