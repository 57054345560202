.form-multiselect {
  background: #fff;
  position: relative;
  border: 1px solid $inputBorderGrey;
  border-radius: 2px;
  box-shadow: inset 1px 1px 2px rgba(#000, .1);
  background: darken(#fff, 2%);
  .form-item--simple & {
    border: none;
    border-radius: 0;
    background: #fff;
    box-shadow: none; } }

.form-multiselect-popup {
  position: absolute;
  top: 100%;
  left: 5px;
  width: calc(100% - 10px);
  @include shadow(10);
  border-radius: 2px;
  z-index: 999;
  background: #fff;
  &:after {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -6px;
    border-width: 6px;
    border-style: solid;
    border-color: transparent transparent #fff transparent; } }

.form-multiselect-popup-inner {
  max-height: 300px;
  overflow: auto; }

.form-multiselect-button {
  display: block;
  width: 100%;
  border: none;
  text-align: left;
  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px $yellow; }
  .form-item--simple & {
    padding: 30px 10px 10px; } }

body .form-multiselect-add input[type="text"] {
  border: none;
  box-shadow: none;
  padding: 10px;
  font-size: 14px;
  &:focus {
    box-shadow: inset 0 0 0 2px #ffca05;
    border-radius: 0; }
  &:hover {
    background: $lighterGrey; }
  .form-item--simple & {
    padding-top: 30px;
    background: none; } }

.form-item--simple .form-multiselect-values--active {
  padding-top: 30px;
  .form-multiselect-add input {
    padding-top: 10px; } }

.form-multiselect-option {
  // border-bottom: 1px solid $borderLighterBlue
  &:last-child {
    border: none; } }

.form-multiselect-option-button {
  display: flex;
  padding: 0;
  border: none;
  background: none;
  text-align: left;
  width: 100%;
  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px $yellow; }
  &:hover {
    background: $lighterGrey; } }

.form-multiselect-option-inner {
  display: flex; }

.form-multiselect-option-icon {
  flex: 0 0 33px;
  display: flex;
  padding: 12px 7px 12px 10px;
  i.fa {
    color: transparent;
    width: 16px;
    height: 16px;
    line-height: 14px;
    border: 1px solid $borderLighterGrey;
    background: $lightGrey;
    display: block;
    font-size: 10px;
    border-radius: 2px;
    text-align: center;
    .form-multiselect-option--selected & {
      color: #fff;
      background: $green2;
      border-color: darken($green2, 5%); } } }

.form-multiselect-option-label {
  flex: 1 0;
  line-height: 20px;
  padding: 10px 0;
  font-size: 14px;
  display: flex;
  min-height: 30px;
  span {
    line-height: 16px;
    margin: auto 0; } }

.form-multiselect-option-remove {}

.form-multiselect-option-remove-button {
  display: block;
  border: none;
  background: none;
  height: 40px;
  width: 30px;
  i.fa {
    font-size: 10px;
    color: $fontColorLight;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    line-height: 20px; }
  &:focus,
  &:hover {
    outline: none;
    i.fa {
      background: $lightGrey;
      color: tomato; } } }

.form-multiselect-group-title {
  padding: 5px 10px;
  background: $lighterGrey;
  font-size: 10px;
  text-transform: uppercase;
  letter-spacing: .5px;
  color: $fontColor;
  font-weight: 600; }

.form-multiselect-option-message {
  text-align: center;
  padding: 10px;
  line-height: 20px;
  color: $fontColorLight;
  font-size: 14px; }

.form-react-multiselect input:focus:not(.tm-input):not(.MuiInputBase-input) {
  outline: none;
  box-shadow: none; }
