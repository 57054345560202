.survey-editor-steps {
  position: relative;
  z-index: 12; }

.survey-editor-steps--template {
  z-index: 1; }

.survey-editor-steps-action {
  text-align: center;
  padding: 10px;
  button {
    margin-right: 10px; } }

.survey-editor-step {
  background: #fff;
  border: 1px solid $borderLighterGrey;
  border-radius: 3px;
  margin-bottom: 20px;
  z-index: 13; }

.survey-editor-step-inner {
  display: flex; }

.survey-editor-step-actions {
  background: #fafafa;
  border-right: 1px solid $borderLighterGrey;
  flex: 0 0 36px;
  padding: 5px;
  border-radius: 3px 0 0 3px; }

.survey-editor-step-action {
  display: block;
  padding: 0;
  border: none;
  background: none;
  width: 26px;
  height: 26px;
  line-height: 26px;
  text-align: center;
  color: $fontColorLight;
  font-size: 12px;
  border-radius: 50%;
  cursor: pointer;
  margin-bottom: 10px;
  .survey-editor-thanks & {
    cursor: default; } }

button.survey-editor-step-action:focus {
  outline: none;
  box-shadow: 0 0 0 2px $yellow; }

.survey-editor-step-action--reorder {
  cursor: move;
  .theme-trustmary:not(.step-dragging) &:hover {
    background: $blue;
    color: #fff; } }

.survey-editor-step-action--remove:hover {
  background: $red;
  color: #fff; }

.survey-editor-step-action--config:hover {
  background: $green2;
  color: #fff; }

.survey-editor-step-config {
  flex: 1 0; }

.survey-editor-step-header {
  display: flex;
  padding: 5px 10px; }

.survey-editor-step-header-title {
  line-height: 26px;
  font-weight: 500;
  font-size: 14px;
  color: $blue;
  margin-right: 5px;
  flex-grow: 1; }

.survey-editor-step-fields {
  padding: 0 10px 10px; }

.survey-editor-step-fields-actions {
  padding-top: 10px; }

.survey-editor-fields-empty {
  background: $pageBackground;
  border-radius: 8px;
  padding: 5px;
  position: relative;
  .editor-panel > & {
    margin: 20px; } }

.survey-editor-fields-empty-inner {
  color: $fontColorLight;
  font-size: 14px;
  height: 30px;
  line-height: 28px;
  text-align: center; }

.survey-editor-step-wrapper {
  position: relative;
  padding: 10px 0; }

.survey-editor-step-drop {
  height: 50px;
  display: none;
  position: absolute;
  right: 0;
  width: 100%;
  body.step-dragging & {
    display: block; } }

.survey-editor-step-drop--before {
  top: 0;
  width: calc(100% - 40px); }

.survey-editor-step-drop--after {
  bottom: 0; }

.survey-editor-template {
  position: relative;
  max-height: 150px;
  overflow: hidden;
  transition: .5s all ease-in-out;
  .survey-editor-step {
    opacity: .5; }
  &:after {
    content: " ";
    display: block;
    width: 100%;
    height: 60px;
    background: linear-gradient(180deg, rgba(#f4f4f4, 0), rgba(#f4f4f4, 1));
    position: absolute;
    bottom: 0;
    left: 0; }
  &.survey-editor-template--open {
    max-height: 3000px;
    .survey-editor-step {
      opacity: 1; } } }

.survey-editor-template-preventer {
  position: absolute;
  z-index: 12;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  button {
    display: block;
    margin: auto auto 20px;
    background: #fff;
    border: none;
    line-height: 24px;
    padding: 0 15px;
    color: $blue;
    border-radius: 12px;
    font-size: 12px;
    font-weight: 500;
    box-shadow: 0 1px 4px rgba(#000, .1);
    transition: .2s box-shadow ease-in-out;
    &:hover {
      box-shadow: 0 3px 8px rgba(#000, .1); }
    &:focus {
      outline: none;
      box-shadow: 0 0 0 2px $yellow; } } }

.survey-editor-step-info {
  padding: 0 10px 10px; }

.survey-editor-step-info--thanks {
  padding-top: 10px; }

.survey-editor-step-type {
  padding: 5px;
  background: $lighterGrey;
  border: 1px solid $borderLightGrey;
  border-radius: 3px;
  position: relative; }

.survey-editor-step-type-inner {
  padding: 7px 7px 7px 40px;
  position: relative;
  background: #fff;
  border: 1px solid $borderLightGrey;
  border-radius: 2px;
  > i.fa {
    display: block;
    width: 30px;
    height: 30px;
    line-height: 30px;
    position: absolute;
    top: 50%;
    margin-top: -15px;
    left: 7px;
    text-align: center;
    color: $green2;
    .survey-editor-step-info--negative_feedback & {
      color: tomato; }
    .survey-editor-step-info--thanks & {
      color: lighten($fontColorLight, 10%); } }
  h3 {
    margin: 0;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px; }
  p {
    margin: 0;
    font-size: 14px;
    color: $fontColorLight;
    line-height: 20px;
    .tag {
      position: relative;
      top: -2px;
      line-height: 18px;
      height: 20px;
      margin-left: 10px; } } }

.survey-editor-step-type-button {
  display: block;
  border: none;
  background: none;
  text-align: left;
  padding: 0;
  &:focus,
  &:hover {
    outline: none;
    h3 {
      text-decoration: underline $green2; } } }
