.embed-editor-header {
  background: #fff;
  border-radius: 8px;
  @include shadow(6);
  height: $headerHeight;
  position: sticky;
  top: 0;
  z-index: 100;
  display: flex;
  .breakpoint-under-medium & {
    display: block;
    height: auto;
    position: static; } }

.embed-editor-nav {
  flex-grow: 1;
  overflow: auto; }

.embed-editor-nav-inner {
  min-width: 730px; }

.embed-editor-nav-item {
  display: inline-block;
  text-decoration: none;
  line-height: 26px;
  padding: 20px 10px 20px;
  color: #333;
  font-size: 14px;
  // font-weight: 500
  margin: 0 5px; }

.embed-editor-nav-item:hover {
  box-shadow: inset 0 -2px 0 $blue; }

.embed-editor-status {
  padding: 17px 10px;
  display: flex;
  .breakpoint-under-medium & {
    padding: 10px;
    border-top: 1px dashed $borderLightGrey; } }

.embed-editor-status-inner {
  color: $fontColorLight;
  line-height: 20px;
  font-size: 14px;
  margin: auto 0;
  i.fa {
    margin-right: 6px;
    font-size: 12px;
    opacity: .7;
    position: relative;
    top: -1px; } }

.breakpoint-under-medium .embed-editor-fullscreen {
  display: none; }

.embed-editor-fullscreen-button {
  display: flex;
  width: 55px;
  height: $headerHeight;
  background: none;
  border: none;
  color: $fontColorLight;
  text-decoration: none;
  &:hover {
    color: $green2; }
  &:focus {
    outline: none;
    box-shadow: inset 0 0 0 2px $yellow; }
  i.fa {
    margin: auto; } }

.embed-editor-nav-css {}

.embed-editor-nav-css-button {
  display: block;
  border: none;
  background: none;
  padding: 12px 13px 13px;
  height: 55px;
  &:hover i.fa {
    color: tomato; }
  &:focus {
    outline: none;
    span {
      box-shadow: 0 0 0 2px $yellow; } }
  span {
    display: inline-block;
    background: $lightGrey;
    height: 30px;
    line-height: 30px;
    padding: 0 15px 0 11px;
    border-radius: 15px;
    font-size: 14px;
    font-weight: 500;
    color: $fontColor;
    i.fa {
      margin-right: 8px;
      font-size: 10px;
      position: relative;
      top: -1px; } } }
