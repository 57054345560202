.form-fieldset {
  display: flex;
  border-top: 1px solid $borderLighterBlue;
  position: relative;
  .form > &:first-child {
    border: none; }
  .breakpoint-under-small & {
    display: block; } }

.form-fieldset .form-fieldset,
.form-fieldset--folded {
  display: block;
  border: none;
  margin-bottom: 10px;
  &:last-child {
    margin-bottom: 20px; }
  .form-item {
    padding-bottom: 15px; } }

.form-fieldset-title {
  flex: 0 0 150px;
  padding-top: 20px;
  .form-fieldset .form-fieldset &,
  .form-fieldset--folded & {
    width: 100%;
    padding: 0 0 10px; }
  .breakpoint-under-small & {
    padding: 20px 0;
    width: 100%; } }

.form-fieldset-title-inner {
  font-size: 16px;
  font-weight: 500;
  position: sticky;
  top: 70px;
  text-align: left;
  .form-fieldset .form-fieldset &,
  .form-fieldset--folded & {
    font-size: 10px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #555;
    font-weight: 700;
    line-height: 18px;
    position: static; }
  .breakpoint-under-small & {
    position: static; }
  i.fa {
    margin-right: 4px; } }

.form-fieldset-fields {
  flex-grow: 1;
  padding-left: 20px;
  padding-top: 20px;
  .breakpoint-under-small & {
    padding: 0; }
  .form-fieldset .form-fieldset &,
  .form-fieldset--folded & {
    width: 100%;
    padding: 0; } }

.breakpoint-over-small {
  .form-fieldset [class*="form-fieldset--cols"] > .form-fieldset-fields,
  [class*="form-fieldset--cols"] > .form-fieldset-fields {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;
    width: calc(100% + 20px);
    > div {
      padding-left: 10px;
      padding-right: 10px; } }
  .form-fieldset--cols-2 > .form-fieldset-fields > div {
    width: 50%;
    &.form-item-style--full {
      width: 100%; } }
  .form-fieldset--cols-3 > .form-fieldset-fields > div {
    width: 33.33%;
    &.form-item-style--full {
      width: 100%; } } }

//
// Table
//

.form-fieldset .form-fieldset--table,
.form-fieldset--table {
  border: 1px solid $borderLightGrey;
  box-shadow: $defaultBoxShadow;
  border-radius: 3px;
  font-size: 14px;
  .form-item {
    display: flex;
    border-bottom: 1px solid $borderLightGrey;
    padding: 0;
    background: darken(#fff, 3%);
    &:hover {
      background: #fff;
      .form-item-label {
        text-decoration: underline $green2; } }
    &:last-child {
      border: none;
      border-radius: 0 4px 4px 0; }
    .form-item-label {
      flex: 0 0 230px;
      margin: 0;
      line-height: 16px;
      padding: 12px 10px;
      color: $fontColor;
      font-size: 14px;
      border-right: 1px solid $borderLightGrey; }
    .form-item-input {
      flex-grow: 1; } } }

.form-fieldset--table .form-checkbox {
  padding: 11px 10px;
  font-size: 14px; }

.form-fieldset--table .form-field--select select,
.form-fieldset--table input[type="text"],
.form-fieldset--table input[type="email"],
.form-fieldset--table input[type="password"],
.form-fieldset--table input[type="number"],
.form-fieldset--table textarea {
  background-color: transparent;
  border: none;
  box-shadow: none;
  border-radius: 0;
  font-size: 14px;
  &:hover {
    background-color: transparent; } }

.form-fieldset--table .form-item-input--wysiwyg .quill {
  border: none;
  border-radius: 0; }

.form-fieldset-table-title {
  padding: 10px;
  font-weight: 500;
  border-bottom: 1px solid $borderLightGrey;
  background: #fff;
  &:first-child {
    border-radius: 2px 0 0 2px; } }

//
// Box
//

.form-fieldset .form-fieldset--box,
.form-fieldset--box {
  display: block;
  border: 1px solid $borderLightGrey;
  // box-shadow: $defaultBoxShadow
  border-radius: 3px;
  margin-bottom: 15px;
  background: #fff;
  .form-fieldset-title {
    padding: 15px;
    border-bottom: 1px solid $borderLightGrey;
    // background: $pageBackground
    border-radius: 3px 3px 0 0;
    color: $fontColor;
    i.fa {
      font-size: 12px;
      margin-right: 6px;
      color: $fontColorLight;
      position: relative;
      top: -2px; } }
  > .form-fieldset-fields {
    padding: 0;
    > .form-item {
      padding: 15px;
      &:hover {
        background: darken(#fff, 2%); } }
    .form-item--wysiwyg:first-child {
      padding: 0;
      .tox-tinymce {
        border: none; } }
    .form-item-style--nopadding {
      padding: 0; } } }

.form-fieldset--red {
  .form-item-label,
  .form-fieldset-title i.fa {
    color: $red; } }

.form-item-input--datetime {
  background: rgba(145, 158, 171, 0.08);
  border-radius: 8px;
  padding: 8px;
  .button--gray {
    background: #fff; } }
