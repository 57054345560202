$lightGrey: #f4f5f6;
$lighterGrey: lighten($lightGrey, 1%);
$grey: #e7e7e7;

$pageBackground: #f1f1ef;
$pageBackgroundDark: #eaeae9;
$pageBackgroundDarker: #eaeae9;

$borderGrey: #e6e8eb;
$borderLightGrey: $borderGrey;
$borderLighterGrey: $borderGrey;
$inputBorderGrey: $borderGrey;

$borderGreyHover: darken($borderGrey, 5%);

$borderBlue: $borderGrey;
$borderLightBlue: $borderGrey;
$borderLighterBlue: $borderGrey;
$inputBorderBlue: $borderGrey;

$inputBackground: rgba(#919EAB, 0.08);
$inputBackgroundActive: rgba(#919EAB, 0.16);
$inputBackgroundDisabled: rgba(#919EAB, 0.24);

//$green: #95d4d1
$green: #42CC54;
$green2: #42CC54;
$yellow: #ffca05;
$blue: #0d3b70;
$tmblue: #0d3b70;
$darkBlue: #212b36;
$lightBlue: #3683be;
$red: #db2828;
$orange: #f9a51a;
$orangeLight: #fec907;
$concrete: #7c828a;
$purple: #ac36ac;

$focus: #159ce4;

$fontColor: #212b36;
$fontColorLight: darken(#9b9b9b, 10%);
$fontColorLighter: lighten($fontColor, 20%);
$fontColorDark: darken($fontColor, 10%);

$fontColorBlue: #8a98aa;

$smallBreakpoint: 400px;
$mediumBreakpoint: 680px;

$containerWidth: 1280px;

$defaultBoxShadow: 0 2px 4px rgba(#000, .05);

$editorCondition: #333;

$arrowDown: url('data:image/svg+xml;base64,CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2aWV3Qm94PSIwIDAgNDQ4IDUxMiI+PHBhdGggZD0iTTIwNy4wMjkgMzgxLjQ3NkwxMi42ODYgMTg3LjEzMmMtOS4zNzMtOS4zNzMtOS4zNzMtMjQuNTY5IDAtMzMuOTQxbDIyLjY2Ny0yMi42NjdjOS4zNTctOS4zNTcgMjQuNTIyLTkuMzc1IDMzLjkwMS0uMDRMMjI0IDI4NC41MDVsMTU0Ljc0NS0xNTQuMDIxYzkuMzc5LTkuMzM1IDI0LjU0NC05LjMxNyAzMy45MDEuMDRsMjIuNjY3IDIyLjY2N2M5LjM3MyA5LjM3MyA5LjM3MyAyNC41NjkgMCAzMy45NDFMMjQwLjk3MSAzODEuNDc2Yy05LjM3MyA5LjM3Mi0yNC41NjkgOS4zNzItMzMuOTQyIDB6IiBmaWxsPSIjOWI5YjliIi8+PC9zdmc+Cg==');

$requiredColor: tomato;

$navWidth: 102px;
$navBackground: $darkBlue;
$navHighlight: rgba($yellow, .2);

$headerHeight: 72px;

@import './boxShadow';

%box-shadow {
  box-shadow: 0 0.3px 0.8px rgba(0, 0, 0, 0.024), 0 0.8px 2.3px rgba(0, 0, 0, 0.035), 0 1.8px 5.4px rgba(0, 0, 0, 0.046), 0 6px 18px rgba(0, 0, 0, 0.07); }

%fa {
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: 'Font Awesome 6 Pro';
  font-weight: 900; }

// Filter bar

%filter-bar {
  background: #fff;
  border-radius: 6px;
  border: 1px solid $borderGrey;
  display: flex;
  margin: 16px 0;
  .breakpoint-under-small & {
    display: block; } }

%filter-bar-tabs {
  border-radius: 8px; }

%filter-bar-list {
  flex: 0 0 260px;
  .breakpoint-under-small & {
    display: none; } }

%filter-bar-fields {
  flex: 1 0;
  display: flex;
  .breakpoint-under-medium & {
    flex-wrap: wrap;
    > div {
      width: 50%; } } }

%filter-bar-actions {
  flex-grow: 1; }

%filter-bar-search {
  flex: 0 0 260px;
  .breakpoint-under-medium & {
    padding-left: 5px; } }

%filter-bar-search-input {
  position: relative;
  input[type="text"]:not(.tm-input):not(.MuiInputBase-input),
  .form--simple & input[type="text"]:not(.tm-input):not(.MuiInputBase-input) {
    width: 100%;
    line-height: 20px;
    box-shadow: none;
    background: none;
    border: none;
    border-radius: 0;
    padding: 18px 30px 17px 30px;
    font-size: 15px;
    &:focus {
      box-shadow: none;
      & + label i.fa {
        color: $green2; } } }
  label {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    i.fa {
      display: block;
      width: 30px;
      text-align: center;
      height: 56px;
      line-height: 56px;
      color: $fontColorLight;
      pointer-events: none;
      font-size: 12px; } }
  &:hover label i.fa {
    color: $green2; } }

%filter-bar-search-clear {
  display: block;
  position: absolute;
  top: 20px;
  right: 10px;
  height: 18px;
  width: 18px;
  border: none;
  border-radius: 50%;
  background: $borderLightGrey;
  color: #fff;
  padding: 0;
  text-align: center;
  i.fa {
    font-size: 10px;
    display: block; }
  &:hover {
    background: $red; }
  &:focus {
    outline: none;
    box-shadow: 0 0 0 1px $green2; } }

%filter-bar-select {
  position: relative;
  label {
    display: block;
    position: absolute;
    top: 5px;
    left: 10px;
    font-size: 10px;
    text-transform: uppercase;
    letter-spacing: .3px;
    color: $fontColorLight;
    line-height: 20px;
    pointer-events: none;
    font-weight: 600;
    i.fa {
      margin-right: 3px;
      font-size: 9px;
      opacity: .5; } }
  select {
    display: block;
    width: 100%;
    background: none;
    border: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    line-height: 20px;
    padding: 25px 30px 10px 10px;
    font-size: 15px;
    color: $fontColor;
    cursor: pointer;
    background-image: $arrowDown;
    background-repeat: no-repeat;
    background-position: calc(100% - 10px) 50%;
    background-size: 12px 12px;
    border-radius: 0;
    &:hover:not([disabled]) {
      text-decoration: underline;
      text-decoration-color: $yellow; }
    &:focus {
      outline: none;
      box-shadow: 0 0 0 1px $green2; }
    &:disabled,
    &:disabled:hover {
      color: #9b9b9b;
      cursor: default;
      text-decoration: none; } }
  .range-datepicker {
    left: 50%;
    transform: translateX(-50%); }
  .range-datepicker-wrapper-button {
    border: none;
    border-radius: 0;
    padding: 25px 30px 10px 10px;
    background: none;
    i.fa {
      display: none; } } }

%filter-bar-plain {
  border: none;
  border-radius: 0;
  margin: 0; }
