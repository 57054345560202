.tags {
  position: relative;
  padding: 0;
  border-radius: 1px;
  font-size: 1em;
  line-height: 1.2;
  cursor: text; }

.tags.is-focused {
  outline: none;
 }  // box-shadow: 0 0 0 2px $yellow

.tags-selected {
  display: inline;
  font-size: inherit;
  line-height: inherit; }

.tags-selected-tag {
  margin: 4px 6px 4px 0;
  padding: 0;
  border: none; }

.tags-selected-tag:hover,
.tags-selected-tag:focus {
  outline: none;
 }  // box-shadow: 0 0 0 2px $yellow

.tags-selected-tag-name {
  display: block;
  margin: auto 0;
  line-height: 20px;
  font-size: 10px;
  color: $fontColor;
  font-weight: 700;
  text-transform: uppercase;
  padding: 0 6px;
  border-radius: 3px;
  letter-spacing: .3px;
  background: $pageBackground;
  border: 1px solid darken($pageBackground, 15%);
  .tags-selected-tag:hover & {
    border-color: $red; } }

.tags-search {
  display: inline-block;
  max-width: 100%;
  margin: 4px 0;
  & input {
    border: none;
    background: none;
    padding: 0;
    line-height: 22px;
    &:focus {
      outline: none;
      box-shadow: none; } } }

@media screen and (min-width: 30em) {
  .tags-search {
    position: relative; } }

.tags-search-input {
  max-width: 100%;
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;
  font-size: inherit;
  line-height: inherit; }

.tags-search-input::-ms-clear {
  display: none; }

.tags-suggestions {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  z-index: 100; }

@media screen and (min-width: 30em) {
  .tags-suggestions {
    width: 240px; } }

.tags-suggestions ul {
  margin: 4px 1px;
  padding: 0;
  list-style: none;
  background: white;
  border: 1px solid #D1D1D1;
  border-radius: 2px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2); }

.tags-suggestions li {
  border-bottom: 1px solid #ddd;
  padding: 6px 8px; }

.tags-suggestions li mark {
  text-decoration: underline;
  background: none;
  font-weight: 600; }

.tags-suggestions li:hover {
  cursor: pointer;
  background: #eee; }

.tags-suggestions li.is-active {
  background: #b7cfe0; }

.tags-suggestions li.is-disabled {
  opacity: 0.5;
  cursor: auto; }

.tags-icon {
  color: $fontColorLight;
  line-height: 30px;
  padding-right: 10px;
  float: left;
  i.fa {
    font-size: 12px; } }
