.embed-editor-fields {
  padding-right: 15px;
  .form-item-input--color {
    background: #fff; }
  .breakpoint-under-small & {
    padding: 0 15px; } }

.embed-editor-section {
  margin-bottom: 10px;
  padding-bottom: 10px; }

.embed-editor-section-title {
  font-size: 12px;
  font-weight: 600;
  line-height: 26px;
  margin-bottom: 3px;
  text-transform: uppercase;
  letter-spacing: .4px;
  color: $fontColor; }

.embed-editor-section-subtitle {
  padding: 4px 10px;
  // border-bottom: 1px solid $borderLighterGrey
  font-size: 11px;
  font-weight: 600;
  line-height: 20px;
  text-transform: uppercase;
  letter-spacing: .4px;
  background: #fff;
  margin-top: -1px;
  position: relative;
  z-index: 3;
  color: $fontColor;
  display: flex;
  > div:nth-child(2) {
    flex-grow: 1;
    margin-left: 10px;
    position: relative;
    &:after {
      content: "";
      display: block;
      position: absolute;
      width: 100%;
      height: 2px;
      left: 0;
      top: 50%;
      margin-top: -1px;
      background: $borderLighterGrey; } } }

.embed-editor-section-fields {
  @include shadow();
  border-radius: 8px; }

.embed-editor-section-fields .embed-editor-section-field:last-child .form-item {
  border: none; }

.embed-editor-section-css {
  text-align: center;
  padding: 10px;
  background: #fff; }

#section-css .embed-editor-section-field {
  max-width: 283px;
  overflow: auto; }
