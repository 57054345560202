.responses {
  position: relative; }

.responses-loader {
  position: absolute;
  top: $headerHeight;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center; }

.responses-loader-inner {
  position: sticky;
  top: 100px;
  background: #fff;
  padding: 10px 15px 10px 10px;
  border-radius: 3px;
  display: inline-block;
  margin: 44px 0 0;
  font-size: 14px;
  line-height: 20px;
  i.fa {
    color: $green2;
    margin-right: 10px;
    font-size: 12px;
    position: relative;
    top: -1px; } }

.responses-error {
  position: absolute;
  top: $headerHeight;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center; }

.responses-error-text {
  position: sticky;
  top: 100px;
  background: #fff;
  padding: 10px 15px 10px 10px;
  border-radius: 3px;
  display: inline-block;
  margin: 44px 0 0;
  font-size: 14px;
  line-height: 20px;
  i.fa {
    color: $green2;
    margin-right: 10px;
    font-size: 12px;
    position: relative;
    top: -1px; } }

.responses-header {
  background: #fff;
  margin-bottom: 20px;
  position: sticky;
  top: 0;
  z-index: 14;
  height: 55px;
  border-radius: 8px;
  .breakpoint-under-medium & {
    position: static;
    height: auto; } }

.responses-filters {
  @extend %filter-bar;
  background: none;
  box-shadow: none;
  position: relative;
  @extend %filter-bar-tabs; }

.responses-filters-fields {
  @extend %filter-bar-fields; }

.responses-filters-list {
  @extend %filter-bar-list; }

.responses-filters-fields {
  @extend %filter-bar-fields; }

.responses-filters-actions {
  @extend %filter-bar-search; }

.responses-filters-search {
  @extend %filter-bar-search;
  flex: 0 0 200px;
  .breakpoint-under-medium & {
    padding-right: 55px; } }

.responses-filters-search-input {
  @extend %filter-bar-search-input; }

.responses-filter-search-clear {
  @extend %filter-bar-search-clear; }

.responses-filters-select {
  @extend %filter-bar-select; }

.responses-content {
  display: flex;
  .responses--loading & {
    opacity: .5;
    pointer-events: none; }
  .breakpoint-under-small & {
    display: block; } }

.responses-content-left {
  flex: 0 0 320px;
  position: relative;
  .breakpoint-under-medium & {
    flex: 0 0 240px; }
  .breakpoint-under-small & {
    display: none; } }

.responses-list {
  position: sticky;
  top: $headerHeight + 5px;
  height: calc(100vh - 66px);
  overflow: auto;
  // border: 1px solid $borderLightGrey
  background: $pageBackgroundDark;
  border-radius: 3px;
  max-width: 320px;
  .breakpoint-under-medium & {
    max-width: 240px; } }

a.responses-list-item {
  display: block;
  text-decoration: none;
  color: $fontColor;
  padding: 9px;
  margin: 5px;
  background: #fff;
  border: 1px solid #fff;
  border-radius: 2px;
  // box-shadow: 0 1px 3px rgba(#000, .1)
  @include shadow(3);
  &:last-child {
    border: none; }
  &:hover {
    opacity: 1;
    background: #fff; }
  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px $yellow; }
  &.responses-list-item--active {
    border-color: $green2; } }

.responses-list-item-content {
  height: 20px;
  margin-bottom: 4px;
  overflow: hidden;
  .responses-list-item--placeholder & {
    color: $fontColorLight; } }

.responses-list-item-info {
  font-size: 13px;
  line-height: 18px;
  color: $fontColorLight;
  font-weight: 500;
  span {
    display: inline-block;
    margin-right: 6px;
    i.fa {
      font-size: 10px;
      margin-right: 4px; } } }

.responses-list-item-tag {
  i.fa {
    position: relative;
    top: -1px; } }

.responses-list-item-tag--contact {
  color: tomato; }

.responses-list-item-tag--public {
  color: $green2; }

.responses-content-right {
  flex-grow: 1; }

.responses-answers {
  padding-left: 20px;
  position: relative;
  .breakpoint-under-medium & {
    padding: 0 10px; } }

.responses-answer {
  background: #fff;
  border-radius: 8px;
  margin-bottom: 20px;
  border: 1px solid $borderGrey; }

// .responses-answer--active
//   box-shadow: 0 0 0 2px $yellow

.responses-answer-info {
  padding: 8px 8px;
  border-bottom: 1px solid $borderLighterBlue;
  font-size: 13px;
  font-weight: 500;
  line-height: 18px;
  color: $fontColorLight;
  display: flex;
  span {
    display: block;
    border-radius: 2px;
    line-height: 20px;
    padding: 0 6px; } }

.responses-answer-app-contact {
  padding: 8px 8px;
  border-bottom: 1px solid $borderLighterGrey;
  font-size: 15px;
  font-weight: 500;
  line-height: 40px;
  color: $fontColorLight;
  display: flex;
  span {
    display: block;
    border-radius: 2px;
    line-height: 24px;
    padding: 2px 6px; }
  a {
    color: #222;
    text-decoration-color: $fontColorLight;
    &:hover {
      text-decoration-color: $green2;
      opacity: 1; } } }

.responses-answer-app-contact-picture {
  padding: 0;
  width: 60px;
  min-width: 60px;
  position: relative;
  img {
    display: block;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
    margin: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -20px 0 0 -20px; } }

.reponses-answer-info-created {
  i.fa {
    font-size: 10px;
    margin-right: 4px;
    position: relative;
    top: -1px;
    opacity: .6; } }

.reponses-answer-info-contact {
  color: tomato; }

.reponses-answer-info-public {
  color: $green2; }

.reponses-answer-info-space {
  flex-grow: 1;
  padding: 0; }

.reponses-answer-info-num {
  color: #fff;
  background: $borderLightGrey;
  .responses-answer--active & {
    background: $green2; } }

.responses-answer-content {
  padding: 15px; }

.responses-empty-inner {
  text-align: center;
  padding: 20px;
  color: $fontColorLight;
  background: $pageBackgroundDark;
  border-radius: 8px;
  font-size: 16px; }

.responses-answer--placeholder {
  opacity: .6;
  box-shadow: none; }

.responses-placeholder-info {
  border-bottom: 1px dashed $borderLighterGrey;
  height: 40px; }

.responses-placeholder-content {
  height: 200px; }

@keyframes responsesPlaceholderLoad {
  0% {
    background-position: 0% 50%; }
  50% {
    background-position: 100% 50%; }
  100% {
    background-position: 0% 50%; } }

.responses-placeholders {
  position: relative;
  &:after {
    content: " ";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to right, rgba($lightGrey, 0) 0%, rgba($lightGrey, .5) 40%, rgba($lightGrey, 1) 50%, rgba($lightGrey, .5) 60%, rgba($lightGrey, 0) 100%);
    background-size: 200% 200%;
    animation: 1s ease-in-out infinite responsesPlaceholderLoad; } }

.breakpoint-under-medium .responses-filters-export {
  width: 55px;
  position: absolute;
  bottom: 0;
  right: 0; }

.responses-export-button {
  display: block;
  width: 55px;
  height: 55px;
  border: none;
  background: none;
  i.fa {
    display: block;
    font-size: 13px;
    width: 36px;
    height: 36px;
    line-height: 36px;
    border-radius: 50%;
    text-align: center;
    color: $fontColorLight;
    margin: 0 auto; }
  &:focus {
    outline: none;
    i.fa {
      box-shadow: 0 0 0 2px $yellow; } }
  &:hover {
    i.fa {
      background: $lightGrey;
      color: $fontColor; } }
  .breakpoint-under-medium & {
    margin: 0 auto; } }

.responses-answer-remove {
  position: relative; }

.responses-answer-remove-button {
  display: inline-block;
  border: none;
  background: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  font-size: 10px;
  padding: 0;
  color: $fontColorLight;
  i.fa {
    position: relative;
    top: -1px; }
  &:hover {
    background: tomato;
    color: #fff; }
  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px $yellow; } }

.responses-answer-question {
  position: absolute;
  top: 100%;
  right: 0;
  background: #fff;
  color: $fontColor;
  text-align: center;
  z-index: 100;
  width: 200px;
  border: 1px solid $borderLighterBlue;
  @include shadow(6);
  border-radius: 3px;
  &:after {
    content: "";
    position: absolute;
    bottom: 100%;
    right: 9px;
    border: 6px solid transparent;
    border-bottom-color: $borderLighterBlue; } }

.responses-answer-question-inner {
  padding: 10px;
  line-height: 16px; }

.responses-answer-question-answers {
  background: $pageBackground;
  padding: 5px;
  border-radius: 0 0 3px 3px; }

.responses-answer-question-answer {
  display: inline-block;
  border: none;
  background: none;
  border-radius: 2px;
  margin: 0 4px;
  color: $fontColorLight;
  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px $yellow; }
  &:first-child {
    background: tomato;
    color: #fff; } }

.responses-answer-question-loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  background: rgba(#fff, .5);
  border-radius: 3px;
  i.fa {
    color: tomato;
    margin: auto;
    font-size: 20px;
    display: block; } }

.responses-answer-question-error {
  position: absolute;
  top: -1px;
  left: -1px;
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  display: flex;
  background: tomato;
  border-radius: 3px;
  color: #fff;
  text-align: center;
  > div {
    margin: auto; }
  i.fa {
    font-size: 20px;
    margin-bottom: 5px;
    color: rgba(#000, .3); }
  span {
    line-height: 16px;
    font-size: 12px; } }

.responses-answer-params {
  padding: 10px 15px 5px;
  border-top: 1px solid $borderLighterGrey;
  display: flex;
  flex-wrap: wrap;
  color: $fontColor; }

.responses-answer-params-block {
  display: block;
  padding: 0;
  border-top: 0;
  .answer-view-field {
    padding: 10px 15px;
    margin: 0;
    &:hover {
      background: $lighterGrey; } } }
