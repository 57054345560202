.form-item {
  padding-bottom: 30px;
  position: relative;
  .modal-form & {
    padding-bottom: 20px; }
  .table & {
    padding: 0; } }

.form-item-style--nobottomborder {
  padding-bottom: 0; }

.form-item-label {
  display: block;
  margin-bottom: 10px;
  font-size: 16px;
  cursor: pointer;
  color: $blue;
  font-weight: 500;
  .form--simple & {
    font-size: 14px;
    margin-bottom: 6px; } }

.form-item--hidden {
  display: none; }

body .form-item--simple {
  padding-bottom: 0px;
  border-bottom: 1px solid $borderLighterBlue;
  .form-item-input input:not(.tm-input):not(.MuiInputBase-input), .form-field > select {
    border: none;
    background: #fff;
    box-shadow: none;
    padding-top: 30px;
    padding-bottom: 10px;
    &:focus {
      box-shadow: inset 0 0 0 2px #ffca05;
      border-radius: 0; }
    &:hover {
      background: $lighterGrey; } }
  .form-item-label {
    z-index: 2;
    font-size: 14px;
    font-weight: 500;
    position: absolute;
    margin-bottom: 0;
    padding: 10px;
    pointer-events: none; }
  .form-checkbox {
    background: #fff;
    padding: 0;
    &:hover {
      background: $lighterGrey; }
    .form-checkbox-icon-wrapper {
      padding: 10px 0 10px 10px; }
    .form-checkbox-label {
      padding: 10px 10px 10px 8px; } }
  .form-item-input--code {
    padding-top: 38px;
    background: #fff;
    .field-input-code-editor {
      border: none;
      border-top: 1px solid $borderLighterBlue; } } }

.field-input-code-editor {
  border: 1px solid $borderLightGrey; }

.form-item-description {
  margin-top: 10px;
  color: $fontColorLight; }

.breakpoint-over-small .form-item-style--inline {
  display: flex;
  &:hover {
    backround: $lightGrey; }
  .form-item-label {
    flex: 0 0 300px;
    margin: 0;
    line-height: 20px;
    padding-top: 11px; }
  .form-item-input {
    flex-grow: 1; } }

.breakpoint-over-small .container-narrow .form-item-style--inline .form-item-label {
  flex: 0 0 240px; }

.form-item-before {
  margin-bottom: 10px; }

.form-item-input {
  position: relative; }

.form-item-after {
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%); }

.form-field--time,
.form-field--select.form-field--time {
  display: inline-flex;
  input:not(.tm-input):not(.MuiInputBase-input) {
    border-radius: 2px 0 0 2px;
    border-right: none;
    flex: 0 0 80px; }
  select {
    border-radius: 0 2px 2px 0; } }

.form-item-link {
  display: block;
  width: 100%;
  background: #fafafa;
  border-radius: 0;
  border: 1px solid $inputBorderGrey;
  line-height: 20px;
  padding: 8px 10px;
  color: $fontColor;
  border-radius: 2px;
  text-decoration: none;
  font-weight: 500;
  font-size: 14px;
  i.fa {
    margin-left: 6px;
    color: $fontColorLight;
    font-size: 12px; }
  &:hover {
    background-color: #fff;
    opacity: 1;
    text-decoration: underline $green2; }
  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px $yellow; }
  &:disabled {
    color: #9b9b9b;
    cursor: default; } }

.form-field--disabled {
  opacity: .5; }

.form-field-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9999; }
